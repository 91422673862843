<template>
  <div class="page-main-box recharge-box">
    <!-- 标题 -->
    <div class="d-flex">
      <Icon name="iconchongzhi" height="20" width="20"></Icon>
      <p class="fz-16 fw-600 ml-16 h-20 lh-20">充值</p>
    </div>
    <!-- 表单 -->
    <div class="mt-60 d-flex justify-center">
      <Form
        ref="formRef"
        :model="formState"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        :rules="rules"
        class="form-box"
      >
        <FormItem label="企业名称">
          <p class="input">{{ formState.company_name }}</p>
        </FormItem>
        <FormItem label="账户/卡号">
          <p class="input">{{ formState.card_no }}</p>
        </FormItem>
        <FormItem label="账户类型">
          <p class="input">{{ formState.account_type_desc }}</p>
        </FormItem>
        <FormItem label="服务">
          <p class="input">{{ formState.account_service_desc }}</p>
        </FormItem>
        <FormItem label="充值金额" name="recharge_amount">
          <a-input-number
            v-model:value="amount"
            :min="0"
            style="width:310px;"
          />
        </FormItem>
        <FormItem label="交易流水号" name="trade_no">
          <a-input v-model:value="formState.trade_no" style="width:310px;" />
        </FormItem>
        <FormItem label="上传凭证" name="oss">
          <div class="d-flex flex-wrap mt-8" style="margin: 0 -8px">
            <template v-for="(item, index) in formState.oss" :key="index">
              <p-uploader
                :value="item"
                @input="e => uploadInputHandle(e, index)"
                :size="{ w: '96px', h: '96px' }"
                class="mx-8 mb-12"
              />
            </template>
            <p-uploader-trigger
              v-if="formState.oss.length < 5"
              @selected="handleSelected"
              :fileSize="5"
              :size="{ w: '96px', h: '96px' }"
              class="mx-8 mb-12"
            />
          </div>
        </FormItem>
        <FormItem label="备注">
          <a-textarea
            placeholder="请输入备注（非必填）"
            showCount
            :maxlength="50"
            :autoSize="{ minRows: 4, maxRows: 4 }"
            v-model:value="formState.remark"
          />
        </FormItem>
        <FormItem :wrapper-col="{ span: 14, offset: 8 }">
          <a-button type="primary" @click="handleSubmit" :loading="isLoading"
            >提交</a-button
          >
          <a-button class="ml-16" @click="handleRouteBack">返回</a-button>
        </FormItem>
      </Form>
    </div>
  </div>
</template>

<script>
import { ref, createVNode, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Form, message, Modal, Message } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { useBatchUpload } from "@/hooks/UseBatchUpload";
import { getInvoiceRules } from "./config";
import { useRechargeApi } from "@/apis/financial";
import $await from "@/utils/await";

export default {
  name: "Recharge",
  components: { Form, FormItem: Form.Item },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const rechargeApi = useRechargeApi();

    const row = route.query.row && JSON.parse(route.query.row);
    const isLoading = ref(false);
    const formRef = ref();
    const amount = ref("");
    const formState = ref({
      company_id: row?.company_id, // 企业ID
      company_name: row?.company_name, // 企业名称
      card_no: row?.card_no, // 卡号
      account_service: row?.account_service, // 服务项id
      account_service_desc: row?.account_service_desc, // 服务项名称
      account_type: row?.account_type, // 账户类型id
      account_type_desc: row?.account_type_desc, // 账户类型名称
      oss: [], // 凭证
      remark: "", // 备注
      trade_no: "", // 交易流水号
      recharge_amount: computed(() => amount.value && amount.value * 100) // 充值金额
    });

    // 图片上传
    const { handleSelected, uploadInputHandle } = useBatchUpload(
      formState,
      "oss"
    );

    const rules = getInvoiceRules(formState);

    // 提交表单
    const handleSubmit = () => {
      const oss = formState.value.oss;
      if (oss.length > 5) {
        Message.error("凭证最多上传3张！");
        return;
      }
      formRef.value
        .validate()
        .then(async () => {
          isLoading.value = true;
          const [, res] = await $await(rechargeApi(formState.value));
          if (res) {
            isLoading.value = false;
            message.success("充值成功");
            router.back();
          } else {
            isLoading.value = false;
          }
        })
        .catch(error => {
          console.log("error", error);
        });
    };
    // 返回
    const handleRouteBack = () => {
      if (formState.value.oss.length || formState.value.recharge_amount) {
        Modal.confirm({
          icon: createVNode(ExclamationCircleOutlined),
          content: "已填写内容，确定要返回到上一页吗？",
          onOk() {
            router.back();
          }
        });
      } else {
        router.back();
      }
    };

    return {
      isLoading,
      amount,
      rules,
      formRef,
      formState,
      handleSubmit,
      handleSelected,
      handleRouteBack,
      uploadInputHandle,
      labelCol: { span: 6 },
      wrapperCol: { span: 18 }
    };
  }
};
</script>

<style lang="less" scoped>
.recharge-box {
  padding: 32px 48px;
  .form-box {
    width: 413px;
  }
  .input {
    background: #f5f5f5;
    border-radius: 4px;
    padding-left: 8px;
    height: 32px;
    line-height: 32px;
  }
}
</style>
<style lang="less">
.recharge-box {
  .ant-upload-select-picture-card {
    background: url("https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/vas/admin/public/upload_pic.png")
      no-repeat center !important;
    background-size: 48px 40px !important;
  }
}
</style>
