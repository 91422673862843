// import * as rule from "@wlhy-web-lib/rule/esm";

export const getInvoiceRules = () => {
  const rules = {
    oss: [
      {
        type: "array",
        required: true,
        trigger: "change",
        message: "上传凭证不能为空"
      }
    ],
    recharge_amount: [
      {
        type: "number",
        required: true,
        trigger: "blur",
        message: "充值金额不能为空"
      },
      {
        trigger: "blur",
        pattern: /^([1-9][0-9]{0,5}00|100000000)$/,
        message: "充值金额范围为1-100万整数"
      }
    ],
    trade_no: [
      {
        type: "string",
        required: true,
        trigger: "blur",
        message: "交易流水号不能为空"
      }
    ]
  };

  return rules;
};
